import * as React from "react"

import { graphql } from "gatsby"
import Header from "components/Header"
import Heading from "components/Heading"
import Footer from "components/Footer"
import Seo from "components/SEO";

import { 
    generalSection,
    generalHeading,
    generalBody,
    generalBodyText,
    generalBodySection
} from './archive-blog.module.css'

const GeneralContent = ({ pageContext, data }) => {

    // let generalContentJSX = (
    //     <div className={generalBodySection} >
    //         <div className={generalBodyText}>
    //             <div dangerouslySetInnerHTML={{__html: shopifyArticle.contentHtml}}></div>
    //         </div>
    //     </div>
    // );

    return (
    <main>
        <Header />

        <section className={generalSection}>
            <div className={generalHeading}>
                <div>
                    <div>
                        <Heading level="h2">{pageContext.title}</Heading>
                    </div>
                    <div>
                        <p>{""}</p>
                    </div>
                </div>
            </div>

            {/* <div className={generalBody}>
                {generalContentJSX}
            </div> */}
        </section>
        <Footer />
    </main>
    )
}

export default GeneralContent;